import React from 'react';
import './App.css';
import  { useState, useEffect } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';

import Navbar from './Navbar';
import Footer from './pages/Footer.js/Footer';
import ChatButton from './ChatButton/ChatButton';
import LoadingScreen from './LoadingScreen/LoadingScreen';
import AnimatedRoutes from './AnimatedRoutes';
import ScrollButton from './ScrollButton/ScrollButton';



function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])


  return (
    loading ? <LoadingScreen /> :
     <Router>
         <Navbar/>

            <AnimatedRoutes/>

         <Footer/>

       
       <ScrollButton/>
     </Router>
  );
}

export default App;
