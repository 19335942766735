import React from 'react';
import './LoadingScreen.css'; 
import { SiMeteor } from 'react-icons/si';

const LoadingScreen = () => {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"> 
        </div>
        <SiMeteor className='loading-icon' />
      </div>
    );
};

export default LoadingScreen;