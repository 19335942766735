import React from 'react';
import './SecondSection.css';
import ProjectManagment from '../../../Assets/card-1.png';
import CloudImage from '../../../Assets/card-2.png';
import DevOpsImage from '../../../Assets/card-3.png';
import WebImage from '../../../Assets/card-4.png';

function SecondSection() {
  return (
  
<div className='cards' >

 <div className=' full-items'>
   <div className='head-card-module'>
        We offer Technology Consulting,
        AI & Machine Learning, and Cloud services.
    </div>


 <div className='cards-section'>
 
    <div class="card1">
        <img src= {ProjectManagment} className='card2-img'/>
        <div className='card2-name'>Project<br/> Management</div>
    </div>

 
    <div class="card2">
            <img src={CloudImage} className='card2-img'/>
            <div className='card2-name'>Cloud <br/> Management</div>
      </div> 

      <div class="card3">
            <img src={DevOpsImage} className='card2-img'/>
            <div className='card2-name'>DevOps <br/> Engineering</div>
      </div>

      <div class="card4">
            <img src={WebImage} className='card2-img'/>
            <div className='card2-name'>Web&App <br/> Development</div>
      </div>
  </div>
 
  <button className='second-button' >
    <span> See All services</span>
  </button>
 
  </div>

  </div>

   
  );
}

export default SecondSection;