import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import LandingSection from '../../LandingSection';
import Content from '../SubModule/Content';
import TopSection from './SubSections/TopSection/TopSection';
import { motion } from 'framer-motion/dist/framer-motion'
import CardsSection from './SubSections/SecondSection/CardsSection';




function Home() {
  return (
   <motion.div
    intial={{opacity : 0}}
    animate={{opacity : 1}}
    exit={{opacity : 0}}
   >
 
      <TopSection/>
      <CardsSection/>
      {/*<LandingSection {...homeObjOne} />
      <HeroSection {...homeObjTwo} />*/}
      <HeroSection {...homeObjThree} />
      {/*<HeroSection {...homeObjFour} />
      <Content/>*/}

   </motion.div>
  );
}

export default Home;
