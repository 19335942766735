import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne } from './Data';
import FirstSection from './SubSections/FirstSection';
import SecondSection from './SubSections/SecondSection';
import { motion } from 'framer-motion/dist/framer-motion'


function AboutUs() {
  return (
    <motion.div
       intial={{opacity : 0}}
       animate={{opacity : 1}}
       exit={{opacity : 0}}
    >
    
      <FirstSection/>
      <SecondSection/>
      <HeroSection {...homeObjOne}/>
      
    </motion.div>
  );
}

export default AboutUs;