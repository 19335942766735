import './ServicesList.css';
import React from 'react';
import cloud from '../Assets/cloud.svg';
import bgdot from '../Assets/bitdots-3.svg';
import consulting from '../Assets/consulting.svg';
import aiml from '../Assets/ai&ml.svg';
import datainfo from '../Assets/data-info.svg';
import serviceimage from '../Assets/service-img.png';



function ServicesList() {
  return (

  <div className='services-list'>

    <img className='top-img-sec' src={bgdot}/>

    <div className='head-module'>
        By providing you with:
    </div>

    <div className='services'>
       
      <div className='service'>

         <img className='service-img' src={cloud}/>

         <div className='service-head'>Cloud  Services</div>
         <div className='service-description'>
            A rock-solid cloud foundation that delivers agility,
            reliability, security, and cost-efficiency.
        </div>

      </div>


      <div className='service'>
         
          <img className='service-img' src={consulting}/>
         
          <div className='service-head'>Technology Consulting</div>
          <div className='service-description'>
             When you consult with us, 
             you'll not only have access to our expertise,
             but you'll also have enthusiastic collaborators.
          </div>

      </div>
     
      <div className='service'>

          <img className='service-img' src={aiml}/>

          <div className='service-head'>AI & Machine Learning</div>
          <div className='service-description'>
             Harness the potential of Machine Learning to innovate
             and gain a competitive edge by optimizing decision-making.
          </div>

      </div>

      <div className='service'>

          <img className='service-img' src={datainfo}/>

          <div className='service-head'>Data architecture services</div>
           <div className='service-description'>
             Achieve unmatched efficiency, effectiveness, 
             and competitiveness by implementing proper data architecture.
           </div>

      </div>
      
    </div>

      <img src={serviceimage} className='srv-imge'/>

  </div>
  
  )
}

export default ServicesList;