import React from 'react';
import './FirstSection.css';
import bgview from '../../../Assets/rect-light.svg';
import AbouUsImage from  '../../../Assets/AboutUs-img.png';


   
function FirstSection() {
  return (
    <div className='about-page'>
     
     <div className='about-us'>

      <img src={bgview} className='bg-view-about'/>
    
         <div className='head-about-page'>
            <h1 className='head-text'> ━ <t/> Our Story </h1>
            <span className='head-text-second'>
                 A History of Connection
            </span> 
         </div>

        <div className='sub-about-page'>
        
          <div className='content-about-page'>
          
          <p className='content-text'>
             Success through Perseverance is the only motto that sparked green meteors.
             We are a group of enthusiastic experienced engineers who started this initiative
             in 2023 with technology consulting, solutions, and,products.
          </p>

          </div>

          <img src={AbouUsImage} className='about-img'/>
        
          <button className='about-button'>
             <span>Lern More</span>
          </button>
      
        </div>
        
      </div>
    </div>
  )
}

export default FirstSection;