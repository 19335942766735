import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Pricing from '../../Pricing';
import { motion } from 'framer-motion/dist/framer-motion';
import FormSection from './SubSections/FormSection.jsx';
import MainForm from './MainForm/MainForm.jsx';

function SignUp() {
  return (
    <motion.div
     intial={{opacity : 0}}
     animate={{opacity : 1}}
     exit={{opacity : 0}}
    >
      
      <MainForm/>
      <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjThree} />
     
    </motion.div> 
  );
}

export default SignUp;
