export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'AI TOOLS AND PLATFORMS',
  headline: 'HR tools powered by AI',
  description:
    'AI tools for HR are apps, software, and tools that use artificial intelligence and machine learning. AI tools helps manage HR compliance through your LMS, reducing time, effort, and resources.',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/svg-10.svg',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATABASE AND PRODUCTS',
  headline: 'Custom No SQL db new Database',
  description:
    'NoSQL database is a non-relational database that stores data in a format other than relational tables. NoSQL databases come in a variety of types based on their data model, such as document, key-value, wide-column, and graph.',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/svg-11.svg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'EASY SETUP',
  headline: 'Super fast and simple onboarding process',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/svg-7.svg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
