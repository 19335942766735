

export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '━ OUR AMBITION ',
  headline: 'Our Mission & Our Vision',
  description:
    ' - Dedicated to creating a more skilled and valuable workforce. - To enhance technical skills so employees and businesses can work better together and increase their value.',
  buttonLabel: 'Know more',
  imgStart: '',
  img: 'images/svg-13.svg',
  alt: 'Credit Card'
};