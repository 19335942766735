import React from "react";
import "./TopSection.css";
import icon from "../../../../Assets/top-icon-g.svg";
import { Link } from "react-router-dom";

function TopSection() {
  return (
    <React.Fragment>
      <div className="section">
        <div className="content-section">
          Where Precision Meets Power.
          <br />
          <span className="green-text">
            {" "}
            Redefining High Performance Scalable Solutions..
          </span>
        </div>
        <div className="content-sub-section">
          <div className="head-sub-section">
            Revolutionize Your Future: Leading Company Delivers Expert
            Technology Consulting, State-of-the-Art AI & Machine Learning, and
            Seamless Cloud Services for Unparalleled Innovation and Success
          </div>
        </div>
        <Link to="/about-us">
          <button className="btn-primary-one">
            {" "}
            <span>Know More</span>
          </button>
        </Link>
        <img src={icon} className="image-section" />
      </div>
    </React.Fragment>
  );
}
export default TopSection;
