import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Pricing from '../../Pricing';
import ServicesList from '../../ServicesList/ServicesList';
import { motion } from 'framer-motion/dist/framer-motion'

function Services() {
  return (
   <motion.div
    intial={{opacity : 0}}
    animate={{opacity : 1}}
    exit={{opacity : 0}}
   >
     
      <ServicesList/>
      <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjThree} />
     
      
   </motion.div>
  );
}

export default Services;
