import React from 'react';
import "./MainForm.css";

function MainForm() {
    return (
        <div className='contact-Main-page'>
            <div className='full-contact-section '>
            <div class="column-left">
                <div className="text-center">CONNECT US</div><br/>    
                <div class="contact-text">We’re always up for a  challenge</div>
                <p className='contact-content'><b>For inquiries, collaborations, or any other questions,<br/> please reach out to us via your preferred communication.</b></p>
           
           <div className='contact-description'>
           <div className='contact-link-items'>
          
            <div to='/' className='contact-name-bottom'>Green Meteors Inc</div>

            <div to='/' className='contact-mail'>Gmail : support@greenmeteors.com</div>

            </div>
          </div>
        </div>

            <div class="card-contact">
                <span class="title">Talk to Us</span>
                <form class="form" >
                    <div class="group">
                        <input placeholder="" type="text" required=""/>
                        <label for="name">Name</label>
                    </div>
                    <div class="group">
                        <input placeholder="" type="email" id="email" name="email" required=""/>
                        <label for="email">Email</label>
                    </div>
                    <div class="group">
                        <textarea placeholder="" id="comment" name="comment" rows="5" required=""></textarea>
                        <label for="comment">Comment</label>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div> </div>
        </div>
    );
}

export default MainForm;
  



 
