import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';



function Footer() {
  return (
    <div className='footer-container'>
     <div className='footer-subscription-div'> <section className='footer-subscription'>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
       <div class="input-group">
           <input type="email" class="input" id="Email" name="Email" placeholder=" Your Email  →" autocomplete="off" />
           <input class="button--submit" value="Subscribe" type="submit" />
          
       </div>
       
      </section>
     
  </div>
        
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Company</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>About</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div><div className='footer-link-items'>
            <h2>Technology</h2>
            <Link to='/'>Cloud Management</Link>
            <Link to='/'>Web & App</Link>
            <Link to='/'>AI & ML</Link>
            <Link to='/'>DevOps</Link>
          </div>
          
        </div>
        <div className='footer-link-wrapper'>
         
          <div className='footer-link-items'>
            <h2>Services</h2>
            <Link to='/'>Innovation & Design</Link>
            <Link to='/'>Development</Link>
            <Link to='/'>Consulting</Link> 
            <Link to='/'>Training</Link> 
           </div>
           <div className='contactf'>
           <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/' className='company-mail'>support@greenmeteors.com</Link>
            </div>
            <div  className='footer-link-items'>
              <Link> Social Media Pages : </Link>
              
            </div>
           <section className='social-media'>
        <div className='social-media-wrap'>
         <div className='social-icons'>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <FaTwitter />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
          </div>
        </div>
      </div>
      {/*section social-items */}
      <small className='website-rights'>  Green Meteors © 2023  • All rights reserved  </small>
    </div>
  );
}

export default Footer;
