import React from 'react';
import Home from './pages/HomePage/Home';
import Services from './pages/Services/Services'
import Products from './pages/Products/Products';
import SignUp from './pages/SignUp/SignUp';
import AboutUs from './pages/AboutUs/AboutUs';
import { Routes,Route, useLocation} from 'react-router-dom';
import { AnimatePresence} from 'framer-motion/dist/framer-motion'

function AnimatedRoutes() { 
    
    const location = useLocation();
  
    return (
   
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path='/' exact element={<Home/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/products' element={<Products/>} />
        <Route path='/sign-up' element={<SignUp/>} />
        <Route path='/about-us' element={<AboutUs/>} />
      </Routes>
   </AnimatePresence>
  )
}

export default AnimatedRoutes;