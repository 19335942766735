import React from 'react';
import './CardsSection.css';
import ProjectManagment from '../../../../Assets/card-1.png';
import CloudImage from '../../../../Assets/card-2.png';
import DevOpsImage from '../../../../Assets/card-3.png';
import WebImage from '../../../../Assets/card-4.png';

function CardsSection() {
  return (
  
<div className='cards-2' >

 <div className=' full-items-2'>

   <div className='head-card-module-2'>
        We offer Technology Consulting,
        AI & Machine Learning, and Cloud services.
    </div>


 <div className='cards-section-2'>
 
    <div class="card1-2">
        <img src= {ProjectManagment} className='card-img'/>
        <div className='card-name'>Project<br/> Management</div>
    </div>

 
    <div class="card2-2">
            <img src={CloudImage} className='card-img'/>
            <div className='card-name'>Cloud <br/> Management</div>
      </div> 

      <div class="card3-2">
            <img src={DevOpsImage} className='card-img'/>
            <div className='card-name'>DevOps <br/> Engineering</div>
      </div>

      <div class="card4-2">
            <img src={WebImage} className='card-img'/>
            <div className='card-name'>Web&App <br/> Development</div>
      </div>
  </div>
 
  <button className='second-button' >
    <span> See All services</span>
  </button>
 
  </div>

  </div>

   
  );
}

export default CardsSection;