export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'We make your wish come true',
  description: '',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/star.svg',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '100% SECURE',
  headline: 'Stay protected 24/7 anywhere anytime',
  description:
    'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/svg-5.svg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'INDUSTRIES',
  headline: 'Unleashing the power of cloud, data, AI, and platforms for industries',
  description:
    "Green Meteors Inc is a technology services company. We work with organizations across industries to help them unleash the power of cloud, data, AI, and platforms to empower people, create possibilities and deliver impact for the better.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/svg-9.svg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved receipts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
