import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Pricing from '../../Pricing';
import { motion } from 'framer-motion/dist/framer-motion'

function Products() {
  return (
    <motion.div
     intial={{opacity : 0}}
     animate={{opacity : 1}}
     exit={{opacity : 0}}
    >
      
       <HeroSection {...homeObjOne} />
       <HeroSection {...homeObjTwo} />
    
    </motion.div>

  );
}

export default Products;
